.App {
  direction: rtl;
  text-align: right;
  background: rgba(40,44,52,1);
  min-height: 100vh;
}

/* Rules for sizing material icons. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-30 { font-size: 30px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.sane-glz-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 162px;
  overflow: hidden;
  background: rgb(71,87,117);
}

.sane-glz-content {
  background: rgb(71,87,117);
  background: linear-gradient(180deg, rgba(71,87,117,1) 0%, rgba(40,44,52,1) 80%, rgba(40,44,52,1) 100%);
  padding-top: 8px;
  position: absolute;
  top: 162px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

}

.sane-glz-content::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
